import React, { useEffect, useState } from "react"
import {
  Card,
  Button,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Progress,
  Alert
} from "reactstrap"
import { QuestionPreview } from "../questions/questionPreview"
import { useDispatch, useSelector } from "react-redux"
import { feedbackFind, feedbackFormSubmit } from "../../store/feedback/feedbackActions"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Pre } from "../../helpers/pre"


export const Preview = () => {

  const navigate = useNavigate()

  const { Feedback } = useSelector(state => state)
  const { feedback } = Feedback
  const dispatch = useDispatch()

  const [pages, setPages] = useState([])

  const [name, set_name] = useState("")
  const [email, set_email] = useState("")
  const [phone, set_phone] = useState("")
  const [response, setResponse] = useState({})
  const [errors , setErrors] = useState([]);
  const [form, setForm] = useState()
  const { id } = useParams()


  const callBack = () => {
    navigate(`/thanks/${id}`)
  }


  const submitForm = () => {
    let e = [];
    for (const [key, value] of Object.entries(response)) {
      const question = feedback.feedback_form_questions.filter(row => row.id == key);
      // if(question[0].mandatory === 'Yes' &&  value.response){}else{
      //   e.push(parseInt(key))
      // }
    }
    setErrors(e)
    // if(e.length == 0){
      dispatch(feedbackFormSubmit({ response, feedback_form_id: feedback.id, name, email, phone, callBack }))
    // }
  }

  const get = () => dispatch(feedbackFind(id))

  useEffect(() => {
    feedback && setForm(feedback)
  }, [feedback])

  useEffect(() => get(), [])

  const submit = () => {

  }

  const [active, setActive] = useState(0)


  useEffect(() => {
  }, [active])



  return <React.Fragment>

    <div className="">
      <Container>
        {feedback && <Card>
          <br />
          {/*<Pre data={feedback} />*/}
          {/*<Progress value={active} min={0} max={(feedback.aspects.length)} />*/}
          <CardBody style={{
            backgroundImage: `url(${feedback?.cover})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "hidden"
          }}>
            <Pre data={errors}></Pre>
            <Row className="mb-5">
              <Col sm={12}>
                <h1 className="text-light ">{feedback.form_name}</h1>
              </Col>
              <Col sm={7}>
                <img className="img-fluid" style={{ height: 100 }} src={feedback.company_logo} />
              </Col>

            </Row>
            <br />
            <br />
            <br />
            <br />
            <br />
            {/*<Pre data={searchParams}/>*/}

          </CardBody>

          {/*<CardBody>*/}
          {/*  {(feedback.type === "Wizard") && <Row>*/}

          {/*    <Col style={{ textAlign: "left" }}>*/}
          {/*      {active > 0 &&*/}
          {/*      <Button*/}
          {/*        onClick={() => setActive(active - 1)}*/}
          {/*        className="btn btn-soft-primary"><i*/}
          {/*        className="mdi mdi-chevron-double-left"></i> Prev</Button>}*/}

          {/*    </Col>*/}
          {/*    <Col style={{ textAlign: "right" }}>*/}


          {/*      {active < feedback.aspects.length && <Button*/}

          {/*        onClick={() => setActive(active + 1)}*/}
          {/*        className="btn btn-soft-primary">Next <i*/}
          {/*        className="mdi mdi-chevron-double-right"></i></Button>}*/}


          {/*      {active === feedback.aspects.length && active < feedback.aspects.length + 1 && <Button*/}

          {/*        onClick={() => setActive(active + 1)}*/}
          {/*        className="btn btn-soft-primary">Submit Feedback <i*/}
          {/*        className="mdi mdi-chevron-double-right"></i></Button>}*/}

          {/*    </Col>*/}

          {/*  </Row>}*/}
          {/*</CardBody>*/}
          <>
            <CardBody>
              {/*<CardTitle className="m-5">Personal Information</CardTitle>*/}
              <Row>
                {feedback.feedback_form_fields.map((row, index) => <Col sm={4} key={`question-${row.id}`}>
                  <FormGroup>
                    <Label>{row.label}</Label>
                    {row.label === "Full Names" &&
                    <Input value={name} onChange={e => set_name(e.target.value)} placeholder={row.placeholder} />}
                    {row.label === "Email Address" &&
                    <Input value={email} onChange={e => set_email(e.target.value)} placeholder={row.placeholder} />}
                    {row.label === "Phone" &&
                    <Input value={phone} onChange={e => set_phone(e.target.value)} placeholder={row.placeholder} />}
                  </FormGroup>
                  {/*<Pre data={row} />*/}
                </Col>)}
              </Row>
            </CardBody>
          </>

          {feedback.aspects.map(({ id, aspect_name }, index) => {

            const aspect_questions = feedback.feedback_form_questions.filter(({ aspect }, index) => aspect == aspect_name)

            // if (aspect_questions.length == 0) return <></>

            return <CardBody key={`aspect-${index}`} hidden={active !== index + 1 && feedback.type === "Wizard"}>
              <>
                {/*<CardTitle className="m-5">{aspect_name} </CardTitle>*/}
                <ul className="list-group">
                  {aspect_questions.map((row, index) => <li key={`question-${row.id}`} className="list-group-item">
                    {errors.includes(row.id) && <small className="text-danger">Please fill in this field</small>}

                    <QuestionPreview question={row} isFeedback appendResponse={res => {
                      let data = response
                      Object.assign(data, res)
                      setResponse(data)
                    }} />

                  </li>)}
                </ul>
              </>
            </CardBody>
          })}

          <CardBody>


            <p style={{ textAlign: "center" }} className="mt-5 mb-5 font-size-16 text-muted"><strong>By completing
              this survey you are consenting to storing and using your data to help us improve our services to
              you</strong></p>


            <div className="text-center">


              <Button className="btn btn-soft-dark btn-lg me-1"
                type="reset"

              >Reset</Button>
              <Button
                onClick={() => submitForm()}
                className="btn btn-soft-primary btn-lg">Submit Feedback <i
                className="mdi mdi-send-outline"></i>

              </Button>
              {/*<Button outline className="btn-soft-primary" onClick={() => submitForm()} >Submit Form</Button>*/}

            </div>
          </CardBody>

        </Card>}


      </Container>
    </div>


    {/*<p style={{textAlign: 'center'}} className="mt-5">Copyright <i className="mdi mdi-copyright"> </i> {feedback.company_name}</p>*/}

  </React.Fragment>
}
